@charset "utf-8";

// Our variables
$base-font-family:     "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:       16px;
$base-font-weight:     400;
$small-font-size:      $base-font-size * 0.875;
$big-font-size:        $base-font-size * 1.875;
$base-line-height:     1.5;

$spacing-unit:         30px;
$small-spacing-unit:   $spacing-unit / 2.0;

$text-color:           #111;
$background-color:     #fdfdfd;
$brand-color:          #2a7ae2;
$green-color:          #1B512D;
$purple-color:         #5E239D;

$grey-color:           #828282;
$grey-color-light:     lighten($grey-color, 40%);
$grey-color-dark:      darken($grey-color, 25%);

// Width of the content area
$content-width:        800px;

$on-palm:              600px;
$on-laptop:            800px;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base",
    "photo-gallery",
    "layout",
    "font-awesome/font-awesome"
;
