.page-content {
    // margin-bottom because of the footer
    margin-bottom: $spacing-unit * 2;
}

a > i.fa {
    color: $grey-color;
}

.cv-list-icon {
    i.fa {
        color: $brand-color;
        margin-left: 5px;
    }

    &:hover {
        text-decoration: none;

        i.fa {
            color: $text-color;
        }
    }
}

.big-avatar {
    width: 80%;
    display: block;
    margin: $spacing-unit auto;
}

.site-header {
    #avatar-hexagon {
        width: $spacing-unit * 12;
        max-width: 90%;
        margin: 0 auto;
        margin-top: $spacing-unit * 2;
    }
}

.site-title {
    display: block;
    text-align: center;
    margin-top: $spacing-unit;
    font-size: $big-font-size;
    color: $text-color;

    h1 {
        font-size: $big-font-size;
    }

    &:visited {
        color: $text-color;
    }

    &:hover {
        text-decoration: none;
    }
}

.site-nav {
    text-align: center;
    margin: $small-spacing-unit;

    .page-link {
        font-size: $base-font-size;
        margin: $small-spacing-unit;
    }
}

.contact-list {
    text-align: center;
    margin-bottom: $small-spacing-unit;
    margin-top: $small-spacing-unit;

    li {
        margin: $small-spacing-unit;
        display: inline;

        a {
            display: inline-block;
        }
    }
}

@media only screen and (max-width: 800px) {
    .contact-list {
        text-align: left;
        margin-bottom: $small-spacing-unit;
        margin-top: $small-spacing-unit;
        margin-left: 0;

        li {
            margin: 0;
            display: block;

            a {
                display: inline-block;
            }
        }
    }

}

.post-list {
    list-style: none;
    margin: 0;

    .post-meta {
        font-size: $small-font-size;
    }
}

.project-list {
    list-style: none;
    margin: 0;

    .project-description {
        margin-bottom: $spacing-unit;
    }
}

.project-header {
    margin-bottom: $small-spacing-unit;

    .project-title {
        display: inline;

        a {
            margin: 0;
        }
    }

    .project-repo-link {
        margin-left: $small-spacing-unit;

    }
}

.photo {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
    padding-top: $spacing-unit - $small-spacing-unit;
    padding-bottom: $spacing-unit - $small-spacing-unit;
}

#not-found {
    h1 {
        text-align: center;
        margin-top: $small-spacing-unit;
    }
}
